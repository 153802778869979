<template>
    <h3>媒體報導</h3>
    <div>香港01 - 麻雀計番app 2024新年竹戰必備｜4機同步方便找數／仲有魚蝦蟹app</div>
    <a target="_blank"  href="https://www.hk01.com/article/989112">https://www.hk01.com/article/989112</a><br />
    <div class="media-capture">
        <img src="../assets/hk01.png" />
    </div>
    <div>流動日報 - 買大細、魚蝦蟹 App 過年家中增添氣氛</div>
    <a target="_blank" href="https://www.newmobilelife.com/2024/02/10/%E8%B2%B7%E5%A4%A7%E7%B4%B0%E3%80%81%E9%AD%9A%E8%9D%A6%E8%9F%B9-app%E3%80%80%E9%81%8E%E5%B9%B4%E5%AE%B6%E4%B8%AD%E5%A2%9E%E6%B7%BB%E6%B0%A3%E6%B0%9B/">https://www.newmobilelife.com/2024/02/10/買大細、魚蝦蟹-app 過年家中增添氣氛/</a><br />
    <div class="media-capture">
        <img src="../assets/newmobilelife.png" />
    </div>
    <div>PCM - 拜年團聚玩骰仔扮專業 《可以骰骰》分析點子趨勢</div>
    <a target="_blank" href="https://www.pcmarket.com.hk/dices-app/">https://www.pcmarket.com.hk/dices-app/</a><br />
    <div class="media-capture">
        <img src="../assets/pcm.png" />
    </div>
</template>

<style scoped>
.media-capture {
    max-width: 90%;
    height: auto;
    display: inline-block;
    box-shadow: rgba(0,0,0, 0.3) 0px 0px 20px;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.media-capture img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>
