<template>
    <div>
        <h2>使用說明</h2>
        <h3>家裡有Android TV / Google TV / Chromecast</h3>
        <div class="instruction">
            <ol>
                <li>下載 TV 應用程式，於TV應用程式點選「遊戲代碼」按鈕。</li>
                <li>下載手機應用程式，利用手機應用程式掃描電視上的遊戲代碼 QR Code，完成配對</li>
                <li>馬上可以使用，於手機輸入骰子結果，電視會同步顯示</li>
            </ol>
        </div>
        <h3>沒有相關的電視或只有Apple AirPlay 之電視</h3>
        <div class="instruction">
            <ol>
                <li>下載手機應用程式，選擇離線遊戲</li>
                <li>可選擇用利 Chromecast Mirror / Apple AirPlay 來把統計畫面鏡像到家中電視</li>
            </ol>
        </div>
    </div>
</template>

<style scoped>
.instruction {
    display: inline-block;
    text-align: left;
}
</style>