<template>
    <div class="cat-container">
        <div class="cat-title">
            手機App
        </div>
        <div class="badge-container">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.benleungcreative.sicsiccontroller">
                <img class="google-play-badge" alt="可以骰骰遙控器 App Google Play下載" src="../assets/google-play-badge.png" />
            </a>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <a target="_blank" href="https://apps.apple.com/us/app/id6475263425">
                <img class="app-store-badge" alt="可以骰骰遙控器 App App Store下載" src="../assets/app-store-badge.png" />
            </a>
        </div>
    </div>
    <br />
    <div class="cat-container">
        <div class="cat-title">
            TV / 平板 APP
        </div>
        <div class="badge-container">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.benleungcreative.bigsmall">
        <img class="google-play-badge" alt="可以骰骰 TV App Google Play下載" src="../assets/google-play-badge.png">
    </a>
        </div>
    </div>
    <br />
    <br />
</template>

<style scoped>
.google-play-badge,
.app-store-badge {
    height: 45px;
    cursor: pointer;
}

.cat-title {
    background: black;
    color: white;
    padding: 4px 0px;
}

.badge-container {
    padding: 8px 16px 4px 16px;
}

.cat-container {
    overflow: hidden;
    display: inline-block;
    border: 1px solid black;
    border-radius: 16px;
}
</style>