<template>
    <div class="sicsic-link">
        <a href="https://sicsic.xyz" target="_blank">
            sicsic.xyz
        </a>
    </div>
    <div class="disclaimer">
        Google Play and the Google Play logo are trademarks of Google LLC.<br />
        Apple and the Apple logo are trademarks of Apple Inc., registered in the US and other countries / regions. App Store is a service mark of Apple Inc.
    </div>
    <br /><br />
    <div class="attribution">
        <a href="https://www.freepik.com/free-psd/samsung-tv-mockup_800771.htm" target="_blank">Image by graphictwister</a> on Freepik
    </div>
</template>

<style scoped>

.sicsic-link {
    margin-bottom: 10px;
}

.disclaimer {
    font-size: 12px;
}

.attribution {
    font-size: 7px;
}
</style>