<template>
    <img class="dice-img" src="../assets/dice.png" alt="可以骰骰 App Logo" />
    <h2>可以骰骰</h2>
    <div>屋企都可以感受賭場般既買大細體驗</div>
    <div>開左幾多舖大幾多舖細，單多定雙多，邊個組合開最多？一睇就知</div>
</template>

<style scoped>
    .dice-img {
        width: 80px;
    }
</style>