<template>
    <div class="screenshotContainer">
        <img src="../assets/full-list-banner.jpg" alt="可以骰骰 App 截圖畫面" />
    </div>
</template>

<style scoped>
.screenshotContainer {
    max-width: 70%;
    margin: 20px auto;
}

.screenshotContainer img {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .screenshotContainer {
        max-width: 99%;
        margin: 20px auto;
    }
}
</style>