<template>
  <title>可以骰骰App</title>
  <TitleSection />
  <TVScreenSection />
  <AppsSection />
  <InstructionSection />
  <MediaCoverage />
  <DisclaimerComp />
</template>

<script>

import TitleSection from './components/TitleSection.vue'
import DisclaimerComp from './components/DisclaimerComp.vue'
import AppsSection from './components/AppsSection.vue'
import TVScreenSection from './components/TVSceenSection.vue'
import InstructionSection from './components/InstructionSection.vue'
import MediaCoverage from './components/MediaCoverage.vue'
export default {
  name: 'App',
  components: {
    TVScreenSection,
    TitleSection,
    AppsSection,
    InstructionSection,
    DisclaimerComp,
    MediaCoverage
}
}
</script>

<style> 
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
  margin-bottom: 50px;
}

a {
  color: #2c3e50;
  text-decoration: none;
}
</style>
